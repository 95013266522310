<template>
  <el-dialog v-if="dialogVisible" :visible.sync="dialogVisible" class="dialog-1200" title="Privacy Policy" width="100%">
    <div class="content">
      <p>Using your personal Information</p>
      <span>
        We at JTRweb Limited understand and respect the importance of your privacy and we are committed to safeguarding your personal information. In providing our service to you, we must collect personal information from you and make subsequent use of it as detailed below.
        <br/>
        You are responsible for ensuring that other members of your party are aware of the content of our Privacy Policy and consent to your acting on their behalf in all your dealings with us. If you do not agree to our use of your information, we cannot do business with you or accept your booking.
      </span>
      <p>How?</p>
      <span>
        We will only collect personal information from you by specifically asking for it:-
        <br/>
        When you buy a ticket from us via our internet booking facilities.
        <br/>
        When you register with us for take part in surveys and customer feedback.
        <br />
        When you email us to make an enquiry.
      </span>
      <p>What information will we collect?</p>
      <!-- <p>WHAT INFORMATION WILL WE COLLECT?</p> -->
      <span>We may collect all or some of the following information relating to you or other members of your travelling party including  email addresses.</span>
      <br>
      <span>We DO NOT COLLECT AND ARE UNABLE TO COLLECT your credit/ debit card or other payment details as after checkout you are transferred to your selected payment company’s secured gateway for payment processing.</span>


      <p>Cookies</p>
      <!-- <p>COOKIES</p> -->
      <span>
        If our contact and dealing with you is via our website(s), we may use "cookies". A cookie is a small piece of data sent from our web server to your computer and stored in a text file on your hard drive. Cookies allow us to identify your computer but not you personally. You can set your web browser to refuse cookies. We use cookies to measure site usage and related information. If you are making a purchase, we may also use cookies to keep track of the transaction from one web page to another.
      </span>
      <p>What will we do with it?</p>
      <span>
        (1) It may be mandatory (as requested government authorities) when you buy services to take place overseas to disclose your information for immigration, security and anti-terrorism purposes, or any other purposes which they determine appropriate. Even if not mandatory, we may exercise our discretion to assist where appropriate.
        <br>
        (2) We collect information relating to customer trends and patterns. This information is often used in its aggregate form. We, including our other brands, may disclose aggregate statistics about enquiries made, visitors, customers and sales in order to describe our services to prospective partners, purchasers, advertisers and other reputable third parties and for other lawful purposes. No personally identifying information is disclosed.
      </span>
      <p>Our security policy</p>
      <span>
        We have taken all reasonable steps to have appropriate security measures in place to protect your information. The transmission of information via the internet is never completely secure. We exclude our liability for personal data lost in transmission to the website.
        <br />
        All sensitive information, including your personal details and credit card information, is kept confidential through the use of a secure socket layer (SSL). This means that information can only be exchanged between you and JTRweb Limited, and that no third party can access this data. The padlock symbol on your browser shows which pages are covered by this security system.
      </span>
      <p>What can I do?</p>
      <span>If you do not agree to our use of the information as set out above, you should inform us as soon as possible by email to us at booking@usjticketing.com</span>
      <p>Updates and changes</p>
      <span>Any changes to this Policy will be posted on our website. We will strive to ensure our practices comply with the most current available version of this Policy.</span>
      <p>Links to other websites</p>
      <span>Our website(s) may contain links to other sites not controlled by us. (1) These sites may send you cookies and collect data and personal information. We are not responsible for the actions, content or the privacy policies of those websites to which our website(s) may link. It is your responsibility to check the status of these sites.</span>
      <p>SSL</p>
      <span>
        All sensitive information, including your personal details and credit card information, is kept confidential through the use of a secure socket layer (SSL). This means that information can only be exchanged between you and JTRweb Limited, and that no third party can access this data. The padlock symbol on your browser shows which pages are covered by this security system. Please refer to our privacy policy for more information.
      </span>
    </div>
    <el-row class="dialog-foot">
      <el-col>
        <div>
          <el-button @click="dialogVisible = false" type="primary" plain>CLOSE</el-button>
        </div>
      </el-col>
    </el-row>
    <close-icon v-if="dialogVisible" @closeDialog="dialogVisible = false" />
  </el-dialog>
</template>

<script>
import closeIcon from '@/components/close_icon.vue'

export default {
  components: { closeIcon },
  data() {
    return {
      dialogVisible: false
    }
  },
  methods: {
    init() {
      this.dialogVisible = true
    }
  }
}
</script>
<style lang="scss" scoped>
   ::v-deep .el-dialog{
    border-radius: 15px;
    max-width: 1200px;
  }
  ::v-deep .el-dialog__header{
    font-weight: bold;
    font-size: 20px;
    padding-bottom: 0;
    padding-top: 29px;
  }
  .content{
    padding: 0 20px;
    height: 459px;
    overflow: auto;
    word-break: break-word;
  }
  ::v-deep .el-dialog__body > .content > p{
    font-size: 16px;
    color: black;
  }
  ::v-deep .el-dialog__body > .content > p:not(:first-child){
    margin-top: 20px;
  }
  span{
    font-size: 14px;
    color: #797979;
  }
   ::v-deep .el-dialog__body{
   padding: 20px 0 0 0;
 }
  ::v-deep .dialog-foot{
    text-align:right;
    background-color: #eeeeee;
    padding: 15px 20px;
    margin-top: 25px;
    border-top: 1px solid#a7a7a7;
    border-radius: 0 0 15px 15px;
  }
  ::v-deep .el-button--primary.is-plain{
    background-color: white;
    border-radius: 13px;
    font-weight: bold;
    border-color: #1073e3;
    color: #1073e3;
  }
  ::v-deep .el-dialog__headerbtn{
    display: none;
  }
  @media (max-width: 768px) {
    /deep/ .el-dialog{
      width: 100% !important;
      margin-top: 0  !important;
      margin-bottom: 0;
      height: 100vh;
      border-radius: 0;
    }
    /deep/ .content{
      height: calc(100vh - 166px);
    }
    .dialog-foot{
      border-radius: 0;
    }
  }
</style>