<template>
  <div class="container font14" v-loading="loading">
    <!-- 占位div -->
    <div :style="{height: $store.state.changeHeight + 'px;'}"></div>
    <div class="payment-sticky-rail">
      <div class="detail-summary" @click="summaryDialogHandle">View Detail Summary</div>
      <div class="total">
        <div class="medium-gotham-bold">
          <span class="total-label">Total:</span>
          <span class="total-price">¥{{ getSubtotal() || 0 | abs }}</span>
        </div>
      </div>
    </div>
    <div class="main flex-space-between" :style="{marginTop: $store.state.changeHeight + 'px'}">
      <div class="main_left">
        <p class="blueFont font_weight_700 back_font" @click="$router.push('/shopCart')">
          <i class="el-icon-arrow-left" />
          <span class="font_weight_700">Back</span>
        </p>
        <h2 class="bigTitle">Checkout</h2>
        <div style="margin-bottom:24px;padding-left:10px;">* indicates require fields.
          <!-- <p>Please note that if the above information is incomplete or incorrect, we reserve the right to cancel the order.</p> -->
        </div>
        <div class="base">1.Contact</div>
        <!-- 联系信息表单 -->
        <el-form :model="form" :rules="rules" ref="ruleForm" label-position="top" label-width="100px">
          <el-form-item label="First Name" prop="firstName">
            <el-input v-model="form.firstName" :maxlength="15" />
          </el-form-item>
          <el-form-item label="Last Name" prop="lastName">
            <el-input v-model="form.lastName" :maxlength="15" />
          </el-form-item>
          <!-- <el-form-item label="Phone Number" prop="mobile">
            <el-input v-model="form.mobile" :maxlength="11" :minlength="10" />
            <el-popover :content="phoneFormatTip" placement="right-start" :trigger="screenWidth < 765 ? 'click' : 'hover'" width="170">
              <i class="el-icon-warning-outline" slot="reference" />
            </el-popover>
          </el-form-item> -->
          <el-form-item label="Phone Number" class="pc-phoneNumber-input" prop="mobile">
            <el-select v-model="form.areaCode" placeholder="Select..." filterable :filter-method="(val) => filterMethod(val, form)" @focus="codeSelectFocus" @hook:mounted="clear" @visible-change="clear" ref="codeSelect">
              <el-option v-for="(item, index) of phoneCodeList" :key="item.areaName" :label="'+' + item.areaCode" :value="item.areaCode">
                <span style="float: left">{{ item.areaName }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">+{{ item.areaCode }}</span>
              </el-option>
            </el-select>
            <el-input v-model="form.mobile" @blur="inpBlur" class="reset-el-select">
            </el-input>
          </el-form-item>
          <el-form-item label="Nationality" prop="nationality">
            <el-select v-model="form.nationality" placeholder="Select..." filterable>
              <el-option v-for="(item, index) in areaList" :key="index" :label="item.areaName" :value="item.areaId" />
            </el-select>
          </el-form-item>
          <el-form-item label="Place of Residence" prop="country">
            <el-select v-model="form.country" placeholder="Select..." @change="changeArea()" filterable>
              <el-option v-for="(item, index) in areaList" :key="index" :label="item.areaName" :value="item.areaId" />
            </el-select>
          </el-form-item>
          <!-- 日本地区的第一级地区,选择日本之后显示 -->
          <el-form-item v-show="form.country === 100001" label="Prefecture" prop="area" :rules="form.country === 100001 ? areaRules : []">
            <el-select v-model="form.area" placeholder="Select..." filterable>
              <el-option v-for="(item, index) in japanAreaList" :key="index" :label="item.areaName" :value="item.areaId" />
            </el-select>
          </el-form-item>
          <el-form-item label="Address" prop="address">
            <el-input v-model="form.address" maxlength="100" />
          </el-form-item>
          <!-- <el-form-item label="Zip/Postal Code" prop="code">
            <el-input v-model="form.code" />
          </el-form-item> -->
          <el-form-item label="Email Address" prop="email">
            <el-input v-model="form.email" @blur="inpBlur" maxlength="60" @keyup.native="emailCheck('ruleForm')" />
          </el-form-item>
          <el-form-item label="Confirm Email Address" prop="repeatEmail">
            <el-input @blur="inpBlur" @keyup.native="emailCheck('ruleForm')" maxlength="60" v-model="form.repeatEmail" />
          </el-form-item>
          <el-form-item label="Verification Code" prop="verCode">
            <el-input v-model="form.verCode" maxlength="6" style="width:50%" />
            <img :src="codeImgData" style="margin-left:2%;height:40px">
            <el-button icon="el-icon-refresh" circle style="border:none" @click="updateVerificationCode"></el-button>
          </el-form-item>
          <el-row class="terms">
            <!-- <el-checkbox v-model="checked" /> -->
            <div class="el-checkbox" @click="checked = !checked">
              <img v-if="checked" src="../../assets/checkmark.png" alt="">
            </div>l have read and agreed to the
            <span @click="termsDialogHandle" class="blueFont font_weight_700">Terms of Service</span>
            & <span @click="privacyDialogHandle" class="font_weight_700 blueFont">Privacy Policy</span>.
          </el-row>
          <el-row class="terms">
            <div class="el-checkbox" @click="checked2 = !checked2">
              <img v-if="checked2" src="../../assets/checkmark.png" alt="">
            </div>
            <span style="cursor: auto;">Please note that if the above information is incomplete or incorrect, we reserve the right to cancel the order.</span>
          </el-row>
          <el-form-item>
            <el-button v-show="checked && chooseProtocol && checked2" type="primary" @click="toPay('ruleForm')" class="mt15 continue-btn-primary">CONTINUE</el-button>
            <el-button v-show="!(checked && chooseProtocol && checked2)" disabled type="info" @click="toPay('ruleForm')" class="mt15 continue-btn-info">CONTINUE</el-button>
          </el-form-item>
        </el-form>
        <div class="base mt15 grayBackground" style="margin-top:32px;">2.Review</div>
      </div>
      <!-- 侧边栏 -->
      <div id="aside" class="aside" style="margin-top:49px;">
        <div id="asideContent">
          <el-card class="box-card">
            <div slot="header" class="clearfix">Order Summary</div>
            <div class="card-body">
              <div v-if="ticketsCopy1.length > 0" class="card-body-title font16">TICKETS</div>
              <div class="ticket-list">
                <div v-for="(item, index) in ticketsCopy1" :key="index" class="ticket-item flex-space-between">
                  <div class="ticket-item-left">
                    <p class="font_weight_700">{{ getTicketTypeName(item.ticketKind) }}</p>
                    <p class="font14">
                      <span v-if="item['1'] && item['1'].num !== 0">{{ item['1'].num }} {{ item['1'].num >= 2 ? 'Adults' : 'Adult' }}</span>
                      <span v-if="item['1'] && item['1'].num > 0 && item['2'] && item['2'].num > 0">+</span>
                      <span v-if="item['2'] && item['2'].num !== 0">{{ item['2'].num }} {{ item['2'].num >= 2 ? 'Children' : 'Child' }}</span>
                      <span v-if="(item['1'] &&item['1'].num > 0 && item['3'] &&item['3'].num > 0) ||(item['2'] &&item['2'].num > 0 && item['3'] &&item['3'].num > 0)">+</span>
                      <span v-if="item['3'] && item['3'].num > 0">{{ item["3"].num }}
                        {{ item["3"].num >= 2 ? "Seniors" : "Senior" }}
                      </span>
                         <span v-if="item['4'] && item['4'].num > 0">{{ item["4"].num }}
                            All Ages
                      </span>
                    </p>
                    <p>{{ formatDate(item.travelDate) }}</p>
                  </div>
                  <!-- <span class="font_weight_700" v-if="item['1'] && item['2'] && item.ticketKind!==1 ">¥{{ (item['1'].num * item['1'].price + item['2'].num * item['2'].price)  || 0 | abs }}</span> -->
                  <span class="font_weight_700" v-if="item['1'] && item['2'] && item['3'] && item.ticketKind!==7">¥{{ (item['1'].num * item['1'].price + item['2'].num * item['2'].price + item['3'].num * item['3'].price)  || 0 | abs }}</span>
                  <span class="font_weight_700" v-if="item['4'] && item['4'].num > 0">¥{{ (item['4'].num * item['4'].price )|| 0 | abs }}</span>
                </div>
                <!-- Express Passes -->
                <div v-if="expressTicket1.length > 0">
                  <div class="card-body-title font16">Express Passes</div>
                  <div class="ticket-list">
                    <div v-for="(item, index) in expressTicket1" :key="index" class="ticket-item flex-space-between">
                      <div v-if="item.num !== 0">
                        <div class="ticket-item-left">
                          <p class="font_weight_700">{{ item.officialName }}</p>
                          <p>{{ item.num }} All Ages</p>
                          <p>{{ formatDate(item.travelDate) }}</p>
                          <!-- <p>{{ item.batName }}</p> -->
                          <p v-for="(item, index) of splitBatName(item.batName)" :key="index">{{ item }}</p>
                        </div>
                      </div>
                      <span class="font_weight_700">¥{{ item.num * item.price || 0 | abs }}</span>
                    </div>
                  </div>
                </div>
                <!-- options -->
                <div v-if="braceletTicket.length > 0">
                  <div class="card-body-title font16">EXTRAS</div>
                  <div class="ticket-list">
                    <div v-for="(item, index) in braceletTicket" :key="index" class="ticket-item flex-space-between">
                      <div v-if="item.num !== 0">
                        <div class="ticket-item-left">
                          <p class="font_weight_700">{{ item.officialName }}</p>
                          <p>{{ item.num }} All Ages</p>
                          <p>{{ formatDate(item.travelDate) }}</p>
                          <!-- <p>{{ item.batName }}</p> -->
                          <p v-for="(item, index) of splitBatName(item.batName)" :key="index">{{ item }}</p>
                        </div>
                      </div>
                      <span class="font_weight_700">¥{{ item.num * item.price || 0 | abs }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div style="border-top: 1px solid gainsboro;padding:15px;">
              <p class="flex-space-between">
                <span>Subtotal:</span>
                <span>￥7,800.00</span>
              </p>
              <p class="flex-space-between">
                <span>Tax:</span>
                <span>￥7,800.00</span>
              </p>
            </div> -->
            <div class="card-foot">
              <div class="flex-space-between">
                <span class="font_weight_700">Total:</span>
                <div>
                  <p class="font_weight_700" style="text-align:right;">¥{{ getSubtotal() || 0 | abs}}</p>
                  <p class="font12" style="font-weight: 400;">(Tax included)</p>
                </div>
              </div>
            </div>
          </el-card>
        </div>
      </div>
    </div>
    <!-- 支付失败弹框 -->
    <transition name="fade" enter-class="fade-in-enter" enter-active-class="fade-in-active" leave-class="fade-out-enter" leave-active-class="fade-out-active">
      <div v-if="failedTip" class="paymentFailed" :style="{marginTop: $store.state.changeHeight + 'px',height:errCodeTip===''?'100px':'',lineHeight:errCodeTip===''?'100px':''}">
        <div>Payment Failed</div>
        <!-- <div v-if="errInfoTip==='Error code E00000001' || errInfoTip==='Error code E00000002' || errInfoTip==='Error code E00000003' ||errInfoTip==='Error code E00000010'"></div>
        <div v-else><div v-if="errCodeTip!=='' && errInfoTip!==''" class="errTipTxt" >{{ errCodeTip }} </div></div> -->
        <div v-if="errInfoTip!==''" class="errTipTxt" >{{errInfoTip}}</div>
        <div v-if="errCodeTip!==''" class="errTipTxt" >Tickets are available at our partner travel agents.</div>
        <div><a v-if="errCodeTip!==''" class="errTipTxt" target="_blank" href="https://www.usj.co.jp/web/en/us/travel" style="color: #fff;" >https://www.usj.co.jp/web/en/us/travel</a></div>
        <i v-if="screenWidth < 765" @click="failedTip = false" class="el-icon-close" :style="{position:errCodeTip===''?'absolute':'',transform:errCodeTip===''?'translateY(-50%)':'none',marginTop:errCodeTip===''?'0px':'15px'}" />
        <i v-else @click="failedTip = false" class="el-icon-close" />

      </div>
    </transition>
    <!-- 协议弹框 -->
    <terms-dialog v-if="termsDialogVisible" ref="termsDialog" />
    <privacy-dialog v-if="privacyDialogVisible" ref="privacyDialog" />
    <notice-dialog ref="noticeDialog"></notice-dialog>
    <summary-dialog v-if="summaryDialogVisible" :ticketscopy="ticketsCopy" :expressticket="expressTicket" :total="getSubtotal()" ref="summaryDialog" />
  </div>
</template>

<script>
import { validEmail } from '@/utils/validate'
import { formatDate } from '@/utils/util'
import { phoneCode } from '@/utils/phone_code.js'
import termsDialog from './terms-dialog'
import privacyDialog from './privacy-dialog'
import summaryDialog from './summaryDialog'
import noticeDialog from './notice-dialog'
import { mixin1, mixin2 } from '@/mixin/index'
import { order, getAreaList, getVerificationCode,getNotice } from '@/api'
import {errCodeList,errInfoList} from '@/utils/ErrorText.js'

export default {
  mixins: [mixin1, mixin2],
  components: { termsDialog, privacyDialog, summaryDialog,noticeDialog },
  watch: {
    screenWidth(val) {
      this.screenWidth = val
    }
  },
  data() {
    // 校验邮箱
    const validemail = (rule, value, callback) => {
      if (!value) {
        callback(new Error('Please enter email address'));
      } else if (!validEmail(value)) {
        callback(new Error('Incorrect email format'))
      } else {
        callback()
      }
    }
    const repeatEmailValid = (rule, value, callback) => {
      if (!value) {
        callback(new Error('Please enter email address'));
      } else if (!validEmail(value)) {
        callback(new Error('Incorrect email format'))
      } else if (this.form.email !== this.form.repeatEmail) {
        callback(new Error('Email Address is inconsistent'))
      } else {
        callback()
      }
    }
    const validMobile = (rule, value, callback) => {
      if (!value || !this.form.areaCode) {
        callback(new Error('Please enter Phone Number'))
      } else {
        callback()
      }
      // value = value.toString()
      // if (!value) {
      //   callback(new Error('Please enter your Phone Number'))
      // } else if (value.length < 10) {
      //   callback(new Error('Incorrect phone format'))
      // } else if (value.length === 11) {
      //   // 如果是11位数必须以080或者090开头
      //   if (!value.startsWith('080') && !value.startsWith('090')) {
      //     callback(new Error('Incorrect phone format'))
      //   } else { callback() }
      // } else {
      //   callback()
      // }
    }
    return {
      form: {
        email: '',
        repeatEmail: '',
        country: '',
        verCode: '',
        uuid: ''
      },
      codeImgData: '',
      rules: {
        firstName: [{ required: true, message: 'Please enter First Name', trigger: 'blur' }],
        lastName: [{ required: true, message: 'Please enter Last Name', trigger: 'blur' }],
        mobile: [{ required: true, validator: validMobile, trigger: ['blur', 'change'] }],
        email: [{ validator: validemail, required: true, trigger: 'keyup' }],
        repeatEmail: [{ validator: repeatEmailValid, required: true, trigger: 'keyup' }],
        nationality: [{ required: true, message: 'Please select Nationality', trigger: 'change' }],
        country: [{ required: true, message: 'Please select Place of Residence', trigger: 'change' }],
        address: [{ required: true, message: 'Please enter a valid address', trigger: 'blur' }],
        code: [{ required: true, message: 'Please enter Zip/Postal Code', trigger: 'blur' }],
        verCode: [{ required: true, message: 'Please enter Verification Code', trigger: 'blur' }],
      },
      areaRules: [{ required: true, message: 'Please select Prefecture', trigger: 'change' }], //area必填验证
      failedTip: true, // 失败提示
     
      orderInfo: {},
      tickets: [],
      expressTicket: [], // ticketsCopy里的快速票
      expressTicket1: [],
      braceletTicket: [], // 手环产品
      ticketsCopy1: [],
      checked: false,
      checked2: false,
      termsDialogVisible: false,
      privacyDialogVisible: false,
      summaryDialogVisible: false,
      serviceAgree: false,
      privacyPollicy: false,
      loading: false,
      chooseProtocol: false, // 是否选择了协议
      areaList: [],
      japanAreaList: [], // 日本的一级地区
      phoneFormatTip: 'Number will be used as a contact solely for delivery or purchase-related issues',
      screenWidth: document.body.clientWidth,
      phoneCodeList: [], // 号码的code
      errCodeTip:'',
      errInfoTip:''
    }
  },
  created() {
    this.phoneCodeList = phoneCode.sort(this.areaSort)
    console.log(this.$route.query)
    if (this.$route.query.payFail) {
      // 报错处理
      this.failedTip = true
      let errCodeArr = errCodeList()
      let errInfoArr = errInfoList()
      let errCode = this.$route.query.errCode
      let errInfo= this.$route.query.errInfo
      if(errCode!==undefined && errInfo!==undefined){
        for(let currentCode in errCodeArr){
        errCode === currentCode?this.errCodeTip=errCodeArr[currentCode]:'' 
      }
      for(let currentInfo in errInfoArr){
        // console.log(currentInfo)
        errInfo === currentInfo?this.errInfoTip=errInfoArr[currentInfo]:'' 
        // console.log(this.errInfoTip)
      }
      }
      // console.log(errCode,errInfo)
      if (JSON.parse(localStorage.getItem("peopleInfo")).email !== '') {
        this.form = JSON.parse(localStorage.getItem("peopleInfo"))
      }
    } else {
      getNotice({ noticeType: 10 }).then(res=>{if(res.data.data.list.length!==0) this.$nextTick(()=>{this.$refs.noticeDialog.init()})})
      this.failedTip = false
    }
    this.loadData()
    this.dataHandle()
    this.ticketsCopy.forEach(item => {
      if (item.ticketKind === 1 || item.ticketKind === 2 || item.ticketKind === 5 || item.ticketKind === 7 || item.ticketKind === 9) {
        this.ticketsCopy1.push(item)
      }
    })
    JSON.parse(localStorage.getItem("orderInfo")).orderRequestProductList.map((item, index) => {
      if (item.ticketKind === 3) {
        this.expressTicket1.push(item);
      } else if (item.ticketKind === 8) {
        this.braceletTicket.push(item);
      }
    });

    this.getAreaList()
    this.getJapanAreaList()
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === 'paymentResponce') {
      next(vm => {
        vm.form = JSON.parse(localStorage.getItem('peopleInfo'))
        vm.checked = true
        vm.checked2 = true
        vm.chooseProtocol = true
      })
    }
    next()
  },
  mounted() {

    let asideContent = document.querySelector('#asideContent')
    asideContent.style.top = 40 + 'px'
    window.addEventListener('scroll', this.handleScroll)
    const that = this
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth
        that.screenWidth = window.screenWidth
      })()
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    clear(async) {
      this.$nextTick((async) => {
			  if (!async) {
			    // ios 手机有延迟问题
			    setTimeout(() => {
			      const { codeSelect } = this.$refs
			      const input = codeSelect.$el.querySelector('.el-input__inner')
			      input.removeAttribute('readonly')
			    }, 200)
			  }
      })
    },
    codeSelectFocus(async){
      this.phoneCodeList = phoneCode.sort(this.areaSort);

      this.$nextTick(() => {
			  if (!async) {
			    // ios 手机有延迟问题
			    setTimeout(() => {
			      const { codeSelect } = this.$refs
			      const input = codeSelect.$el.querySelector('.el-input__inner')
			      input.removeAttribute('readonly')
			    }, 200)
			  }
      })
    },
    filterMethod(query,form) {
      // form.areaCode = query

      query = query.toLowerCase();
      console.log(query);
      this.phoneCodeList = phoneCode.sort(this.areaSort).filter(item => {
        // console.log(item);
        return item.areaName.toLowerCase().includes(query) || item.areaCode.toString().includes(query)
      })
    },
    loadData() {
      if (JSON.parse(localStorage.getItem('orderInfo')) !== null) {
        this.orderInfo = JSON.parse(localStorage.getItem('orderInfo'))
        this.tickets = this.orderInfo.orderRequestProductList
      }
      getVerificationCode().then(res => {
        this.form.uuid = res.data.data.key
        this.codeImgData = res.data.data.codeImgData
      })
    },
    updateVerificationCode() {
      getVerificationCode().then(res => {
        this.form.uuid = res.data.data.key
        this.codeImgData = res.data.data.codeImgData
      })
    },
    // 下单
    toPay(formName) {
      if (!this.checked) {
        return this.$message({
          message: 'Please select to have read and agreed to the terms of service',
          type: 'warning',
          duration: 5000
        })
      }
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.loading = true
          this.tickets = []
          this.ticketsCopy1.map(item => {
            this.tickets.push(...this.splitObj(item))
          })
          // google统计-提交订单事件埋点
          this.$gtag.event("checkoutContinue", {
            event_category: "checkoutContinue",
            event_label: "checkoutContinue",
            value: 1000
          });
          this.tickets.push(...this.expressTicket1)
          this.tickets.push(...this.braceletTicket)
          this.orderInfo.orderRequestProductList = this.tickets
          localStorage.setItem('orderInfo', JSON.stringify(this.orderInfo))// 更新本地数据
          // 如果不是japan清空area值
          if (this.form.country !== 100001) {
            this.form.area = ''
          }
          console.log(this.form);
          localStorage.setItem('peopleInfo', JSON.stringify(this.form))
          const peopleInfo = {
            name: this.form.firstName + this.form.lastName,
            mobile: this.form.areaCode + this.form.mobile,
            country: this.form.country,
            nation: this.form.nationality,
            area: this.form.area,
            address: this.form.address,
            addressNo: this.form.code,
            email: this.form.email,
          }
          this.orderInfo.email = this.form.email
          this.orderInfo.orderRequestProductList.forEach(item => {
            item.peopleDtoList = []
            item.peopleDtoList.push(peopleInfo)
          })
          this.orderInfo.orderRequestProductList.forEach((item, index) => {
            if (item.productId < 0) {
              this.orderInfo.orderRequestProductList.splice(index, 1)
            }
          })
          this.orderInfo.payType = 3 // stripe支付
          // this.orderInfo.payType = 7 // paypal支付
          const orderInfoCopy = JSON.parse(JSON.stringify(this.orderInfo)) // 深克隆，不改变页面显示的数据
          const params = await this.handleParams(orderInfoCopy)
          // localStorage.setItem('params', JSON.stringify(params))
          // this.$router.push({ path: '/payResponce'})
          let outerOrderId = '' //订单id
          let orderStatus = false
          await order(params, { uuid: this.form.uuid, verCode: this.form.verCode }).then(res => {
            this.loading = true
            if (res.data.code !== 0) {
              this.loading = false
              return this.$message({
                message: res.data.msg,
                type: "error",
                duration: 5000,
              })
            } else {
              this.loading = false
              orderStatus = true
              outerOrderId = res.data.data
              localStorage.setItem("outerOrderId", outerOrderId)
              // localStorage.setItem("orderInfo", JSON.stringify(this.orderInfo))
              this.$router.push({ name: 'paymentResponce' })
              this.failedTip = false
            }
          })
        }
      })
    },
    // 键盘弹起触发验证
    emailCheck(formName) {
      this.$refs[formName].validate()
    },
    // 下单参数处理
    handleParams(val) {
      var orderRequestProductList = val.orderRequestProductList
      orderRequestProductList.map(item => {
        delete item.ticketName
        // 快速票处理
        if (![1, 2, 5].includes(item.ticketKind)) {
          delete item.batName
          delete item.title
        }
      })
      //把票数为0去掉
      const filterArr = orderRequestProductList.filter(item => {
        return item.num !== 0
      })
      // 把价格改为总价
      filterArr.map(item => {
        item.totalAmount = item.num * item.price
        delete item.price
      })
      val.orderRequestProductList = filterArr
      val.uuid = this.form.uuid
      val.verCode = this.form.verCode
      return val
    },
    splitBatName(str) {
      if(str===undefined) return
      if (str.length===0) return
      if (!str) {
        return ''
      }
      let strArr = str.split(')')
      for (let i = 0; i < strArr.length; i++) {
        strArr[i] += ')'
      }
      return strArr.splice(0, strArr.length - 1)
    },
    // 选择japan时候触发
    changeArea() {
      if (this.form.country === 100001) {
        getAreaList({ parentIds: 100001 }).then(res => {
          if (res.data.code === 0) {
            res.data.data.map(item => {
              item.areaName = item.areaName.trim() // 去掉空格
            })
            this.japanAreaList = res.data.data
            this.japanAreaList.sort(this.areaSort)
          } else {
            this.japanAreaList = []
          }
        })
      } else {
        this.$set(this.form, 'area', '')
      }
    },
    getJapanAreaList() {
      getAreaList({ parentIds: 100001 }).then(res => {
        if (res.data.code === 0) {
          this.japanAreaList = res.data.data
          res.data.data.map(item => {
            item.areaName = item.areaName.trim() // 去掉空格
          })
          this.japanAreaList.sort(this.areaSort)
        } else {
          this.japanAreaList = []
        }
      })
    },
    // 地方按字母排序
    areaSort(a, b) {
      if (a.areaName.toLowerCase() < b.areaName.toLowerCase()) return -1
      if (a.areaName.toLowerCase() > b.areaName.toLowerCase()) return 1
      return 0
    },
    // 获取国籍（地区）
    getAreaList() {
      const param = {
        parentIds: '100,101,102,103,104,105'
      }
      getAreaList(param).then(res => {
        if (res.data.code === 0) {
          this.areaList = res.data.data
          res.data.data.map(item => {
            item.areaName = item.areaName.trim() // 去掉空格
          })
          this.areaList.sort(this.areaSort)
        } else {
          this.areaList = []
        }
      })
    },
    // 计算总价
    getSubtotal() {

      let total = 0
    if (this.ticketsCopy1.length > 0) {
        this.ticketsCopy1.forEach(item => {
          if (item["1"] && item["2"] && item["3"]) {
            total +=
              item["1"].num * item["1"].price + item["2"].num * item["2"].price + item["3"].num * item["3"].price;
          } else if (item["1"] && item["2"]) {
            total +=
              item["1"].num * item["1"].price + item["2"].num * item["2"].price
          } else if (item["1"] && item["2"] && item["3"] && item["4"]){
             total +=
              item["1"].num * item["1"].price + item["2"].num * item["2"].price + item["3"].num * item["3"].price + item["4"].num * item["4"].price;
          } 
           if (item["4"]){
            total +=item["4"].num * item["4"].price
          }
        });
      }
      if (this.expressTicket1.length > 0) {
        this.expressTicket1.map(item => {
          total += item.num * item.price
        })
      }
      if (this.braceletTicket.length > 0) {
        this.braceletTicket.map(item => {
          total += item.num * item.price
        })
      }
      return total
    },
    getTicketTypeName(value) {
      const options = {
        1: '1 Day Studio Pass',
        2: '2 Day Studio Pass',
        5: '1.5 Day Studio Pass',
        7: "NO LIMIT! Countdown 2025 PARTY PASS",
        9: "1 Day Studio Pass + Have Fun in Kansai",
      }
      return options[value]
    },
    // 邮箱验证
    inpBlur() {
      this.chooseProtocol = false
      const { email, repeatEmail, mobile } = this.form
      if (email && repeatEmail && mobile) {
        if (email === repeatEmail) {
          this.chooseProtocol = true
        }
      }
    },
    // 打开费用弹框
    summaryDialogHandle() {
      this.summaryDialogVisible = true
      this.$nextTick(() => {
        this.$refs.summaryDialog.init()
      })
    },
    termsDialogHandle() {
      this.termsDialogVisible = true
      this.$nextTick(() => {
        this.$refs.termsDialog.init()
      })
    },
    privacyDialogHandle() {
      this.privacyDialogVisible = true
      this.$nextTick(() => {
        this.$refs.privacyDialog.init()
      })
    },
    formatDate(val) {
      return formatDate(val)
    }
  }
}
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  .main {
    // width: 1200px;
    max-width: 75rem;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
    position: relative;
    .aside {
      #asideContent {
        position: relative;
        top: 0;
      }
      .card-body-item-base {
        padding: 16px;
        padding-bottom: 24px;
        max-height: 300px;
        overflow: auto;
      }
      .card-body-item-active {
        border-left: 2px solid #0959c2;
        background-color: #f9fcff;
      }
    }
    .main_left {
      flex: 1;
      margin-right: 30px;
    }
  }
}

.ticket-list {
  padding-bottom: 16px;
  .ticket-item {
    padding: 16px;
  }
}
.ticket-item-left {
  max-width: 218.66px;
  // margin-right: 20px;
}
.bigTitle {
  font-size: 24px;
  line-height: 1.25;
  font-weight: 900;
  padding-left: 10px;
  margin-top: 4px;
  // margin-bottom: 19.92px;
}
.card-foot {
  padding: 16px 15px 28px 15px;
  border-top: 1px solid gainsboro;
  font-size: 16px;
}
.el-icon-arrow-left {
  font-weight: 700;
  font-size: 25px;
  position: relative;
  top: -6px;
  &::before {
    vertical-align: middle;
  }
}
.base {
  padding: 8px 16px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  background: #e1f0ff;
  font-size: 16px;
  margin-bottom: 24px;
}
.aside {
  width: 360px;
  ::v-deep .el-card {
    border-radius: 12px;
  }
  ::v-deep .el-card__header {
    padding: 16px 0;
    font-size: 16px;
    font-weight: 700;
  }
  .card-body-title {
    padding: 13px 16px 11px;
    background-color: #f9f9f9;
    border: 1px solid #8080801f;
    font-weight: 600;
  }
}
.paymentFailed {
  position: fixed;
  top: 90px;
  text-align: center;
  background-color: #e61b23;
  width: 100%;
  // height: 100px;
  // line-height: 100px;
  color: white;
  font-size: 20px;
  font-weight: 400;
}
.el-icon-close {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10%;
  font-size: 27px;
  font-weight: 900;
}
.fade-in-active,
.fade-out-active {
  transition: all 0.5s ease;
}
.fade-in-enter,
.fade-out-active {
  opacity: 0;
}
.continue-btn-primary {
  border-radius: 13px;
  background-color: rgb(3, 108, 226);
}
.continue-btn-info {
  border-radius: 13px;
}
.el-button + .el-button {
  margin-left: 0;
}
.pay-btn {
  border-radius: 13px;
  background-color: #52a41d;
}
.grayBackground {
  background-color: #dbdbdb;
}
.terms {
  margin: 15px 0 10px 0;
  span {
    cursor: pointer;
  }
}
.pl25 {
  padding-left: 25px;
}
.back_font {
  margin-top: 24px;
  span {
    font-size: 12px;
    vertical-align: top;
    cursor: pointer;
  }
}
.mb-phoneNumber-input {
  display: none;
}
::v-deep.pc-phoneNumber-input {
  @media (max-width: 750px) {
    .el-form-item__content {
      flex-direction: column;
      .el-input__inner {
        border-radius: 12px !important;
      }
    }
  }
}
::v-deep.pc-phoneNumber-input {
  display: block;
  .el-form-item__content {
    display: flex;
    .el-input__inner {
      &:first-child {
        border-radius: 12px 0 0 12px;
      }
      &:last-child {
        border-radius: 0 12px 12px 0;
      }
    }
  }
}
@media (max-width: 1200px) {
  .container {
    width: 100%;
    .main {
      width: 100%;
      padding: 0 0.9375rem;
      box-sizing: border-box;
    }
  }
}

@media (max-width: 750px) {
  .main {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    .main_left {
      margin-right: 0;
      width: 100%;
    }
  }
  .aside {
    display: none;
  }
  .mb-phoneNumber-input {
    display: block;
  }
  .el-icon-close {
  position: initial;
   transform: none;
   margin-top: 10px;
}
  .errTipTxt{
    font-size: 15px;
  }
}
.payment-sticky-rail {
  display: none;
}
@media (max-width: 375px) {
  .payment-sticky-rail {
    border-bottom: 1px solid #dbdbdb;
    padding: 20px;
    cursor: pointer;
    align-items: center;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
    justify-content: space-between;
    .detail-summary {
      color: #036ce2;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.25;
      cursor: pointer;
    }
    .total {
      .medium-gotham-bold {
        font-size: 17px;
        line-height: 17px;
        font-weight: 700;
        .total-label {
          margin-right: 15px;
          font-size: 16px;
          line-height: 1.25;
          font-weight: 500;
        }
        .total-price {
          font-size: 16px;
          line-height: 1.25;
          font-weight: 900;
        }
      }
    }
  }
}
::v-deep .el-form-item__label::before {
  display: none;
}
::v-deep .el-form-item {
  margin-bottom: 16px;
}
::v-deep .el-form-item__label::after {
  content: "*";
  margin-left: 7px;
}
::v-deep .el-card__header {
  text-align: center;
}
::v-deep .el-card__body {
  padding: 0;
}
::v-deep .el-form--label-top .el-form-item__label {
  padding: 0;
  font-size: 16px;
  line-height: 1.8px;
  font-weight: 500;
  color: #333;
}
::v-deep .el-input__inner {
  border-radius: 12px;
  border: 1px solid #cacaca;
}
.el-select {
  width: 100%;
}
::v-deep .el-checkbox {
  margin-right: 5px !important;
  height: 22px;
  width: 22px;
  background-color: #fff;
  color: #fff;
  border: 2px solid #036ce2;
  border-radius: 3px;
  position: relative;
  top: 5px;
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #036ce2;
  }
}
.el-icon-warning-outline {
  position: absolute;
  left: 128px;
  top: -18px;
  font-size: 18px;
}
</style>