<template>
  <el-dialog v-if="dialogVisible" :visible.sync="dialogVisible" width="100%">
    <h4 class="order-summary">Order Summary</h4>
    <h5 v-if="ticketscopy.length > 0" class="section-heading">TICKETS</h5>
    <div class="section">
      <div v-for="(item, index) in ticketscopy" :key="index" class="ticket-list-item">
        <div class="tickets">
          <div class="ticket-heading">{{ getTicketTypeName(item.ticketKind) }}</div>
          <div class="ticket-num">
            <span v-if="item['1'] && item['1'].num !== 0">{{ item['1'].num }} {{ item['1'].num >= 2 ? 'Adults' : 'Adult' }}</span>
            <span v-if="item['1'] && item['1'].num > 0 && item['2'] && item['2'].num > 0">+</span>
            <span v-if="item['2'] && item['2'].num !== 0">{{ item['2'].num }} {{ item['2'].num >= 2 ? 'Children' : 'Child' }}</span>
          </div>
          <div class="ticket-time">{{ formatDate(item.travelDate) }}</div>
        </div>
        <div v-if="item['1'] && item['2']" class="ticket-price">¥{{ (item['1'].num * item['1'].price + item['2'].num * item['2'].price)  || 0 | abs }}</div>
      </div>
    </div>
    <h5 v-if="expressticket.length > 0" class="section-heading">Express Passes</h5>
    <div class="section">
      <div v-for="(item, index) in expressticket" :key="index" class="ticket-list-item">
        <div class="tickets">
          <div class="ticket-heading">{{ conversionTicketName(item.productId) }}</div>
          <div class="">{{ item.batName }}</div>
          <div class="ticket-num">{{ item.num }} All Ages</div>
          <div class="ticket-time">{{ formatDate(item.travelDate) }}</div>
        </div>
        <div class="ticket-price">¥{{ item.num * item.price || 0 | abs }}</div>
      </div>
    </div>
    <div class="total">
      <div class="total-label">Total:</div>
      <div class="total-price">¥{{ total || 0 | abs}}</div>
    </div>
    <close-icon v-if="dialogVisible" @closeDialog="dialogVisible = false" />
  </el-dialog>
</template>

<script>
import closeIcon from '@/components/close_icon.vue'
import { formatDate } from '@/utils/util'
import { mixin2 } from '@/mixin/index'


export default {
  props: ['ticketscopy', 'expressticket', 'total'],
  mixins: [ mixin2 ],
  components: { closeIcon },
  data() {
    return {
      dialogVisible: true
    }
  },
  methods: {
    init() {
      this.dialogVisible = true
    },
    // 配置一日两日票名
    getTicketTypeName(value) {
      const options = {
        1:'1 Day Studio Pass',
        2: '2 Day Studio Pass',
        5: '1.5 Day Studio Pass',
        7: "NO LIMIT! Countdown 2025 PARTY PASS",
        9: "1 Day Studio Pass + Have Fun in Kansai",
      }
      return options[value]
    },
    formatDate(val) {
      return formatDate(val)
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .el-dialog {
    height: 100vh;
    margin: 0 !important;
    .el-dialog__body{
      padding: 0;
    }
  }
  .order-summary{
    text-align: left;
    padding-bottom: 12px;
    margin-top: 0;
    padding-left: 16px;
    font-size: 20px;
    line-height: 1.25;
    font-weight: 900;
    color: #333;
    margin-bottom: 16px;
  }
  .section-heading{
    font-size: 16px;
    line-height: 1.25;
    font-weight: 600;
    padding: 13px 16px 11px;
    margin: 0;
    border-top: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;
    padding-left: 10px;
    background-color: #f9f9f9;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    color: #0a0a0a;
  }
  .section{
    padding: 16px;
    color: #333;
    .ticket-list-item{
      padding-bottom: 16px;
      display: flex;
      justify-content: space-between;
      .tickets{
        margin-bottom: 16px;
        flex: 0 0 75%;
        max-width: 75%;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.5;
        .ticket-heading{
          font-size: 14px;
          line-height: 1.25;
          font-weight: 700;
        }
        .ticket-time{
          font-size: 14px;
          line-height: 1.5;
          font-weight: 400;
          padding-top: 4px;
        }
        .ticket-num{
          font-size: 14px;
          line-height: 1.5;
          font-weight: 400;
          padding-top: 4px;
        }
      }
      .ticket-price{
        font-size: 14px;
        line-height: 1.25;
        font-weight: 700;
        text-align: right;
      }
    }
  }
  .total{
    padding-bottom: 12px;
    padding-top: 16px;
    font-size: 16px;
    line-height: 1.25;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    color: #0a0a0a;
    border-top: 1px solid #dbdbdb;
    .total-label{
      padding-right: .625rem;
      padding-left: .625rem;
    }
    .total-price{
      text-align: right;
      -webkit-box-flex: 1;
      flex: 1 1 0px;
      padding-right: .625rem;
      padding-left: .625rem;
      min-width: 0;
    }
  }
</style>