export const phoneCode = [
  {"areaName":"Andorra","areaCode":376},
  {"areaName":"United Arab Emirates","areaCode":971},
  {"areaName":"Afghanistan","areaCode":93},
  {"areaName":"Antigua and Barbuda","areaCode":1268},
  {"areaName":"Anguilla","areaCode":1264},
  {"areaName":"Albania","areaCode":355},
  {"areaName":"Armenia","areaCode":374},
  {"areaName":"Angola","areaCode":244},
  {"areaName":"Argentina","areaCode":54},
  {"areaName":"American Samoa","areaCode":1684},
  {"areaName":"Austria","areaCode":43},
  {"areaName":"Australia","areaCode":61},
  {"areaName":"Aruba","areaCode":297},
  {"areaName":"Azerbaijan","areaCode":994},
  {"areaName":"Bosniaand Herzegovina","areaCode":387},
  {"areaName":"Barbados","areaCode":1246},
  {"areaName":"Bangladesh","areaCode":880},
  {"areaName":"Belgium","areaCode":32},
  {"areaName":"Burkina Faso","areaCode":226},
  {"areaName":"Bulgaria","areaCode":359},
  {"areaName":"Bahrain","areaCode":973},
  {"areaName":"Burundi","areaCode":257},
  {"areaName":"Benin","areaCode":229},
  {"areaName":"Bermuda","areaCode":1441},
  {"areaName":"Brunei","areaCode":673},
  {"areaName":"Bolivia","areaCode":591},
  {"areaName":"Caribisch Nederland","areaCode":599},
  {"areaName":"Brazil","areaCode":55},
  {"areaName":"Bahamas","areaCode":1242},
  {"areaName":"Bhutan","areaCode":975},
  {"areaName":"Botswana","areaCode":267},
  {"areaName":"Belarus","areaCode":375},
  {"areaName":"Belize","areaCode":501},
  {"areaName":"Canada","areaCode":1},
  {"areaName":"Democratic Republic of theCongo","areaCode":243},
  {"areaName":"Central African Republic","areaCode":236},
  {"areaName":"Republic Of The Congo","areaCode":242},
  {"areaName":"Switzerland","areaCode":41},
  {"areaName":"Ivory Coast","areaCode":225},
  {"areaName":"Cook Islands","areaCode":682},
  {"areaName":"Chile","areaCode":56},
  {"areaName":"Cameroon","areaCode":237},
  {"areaName":"China","areaCode":86},
  {"areaName":"Colombia","areaCode":57},
  {"areaName":"CostaRica","areaCode":506},
  {"areaName":"Cuba","areaCode":53},
  {"areaName":"Cape Verde","areaCode":238},
  {"areaName":"Curacao","areaCode":599},
  {"areaName":"Cyprus","areaCode":357},
  {"areaName":"Czech","areaCode":420},
  {"areaName":"Germany","areaCode":49},
  {"areaName":"Djibouti","areaCode":253},
  {"areaName":"Denmark","areaCode":45},
  {"areaName":"Dominica","areaCode":1767},
  {"areaName":"dominican republic","areaCode":1809},
  {"areaName":"Algeria","areaCode":213},
  {"areaName":"Ecuador","areaCode":593},
  {"areaName":"Estonia","areaCode":372},
  {"areaName":"Egypt","areaCode":20},
  {"areaName":"Eritrea","areaCode":291},
  {"areaName":"Spain","areaCode":34},
  {"areaName":"Ethiopia","areaCode":251},
  {"areaName":"Finland","areaCode":358},
  {"areaName":"Fiji","areaCode":679},
  {"areaName":"Micronesia","areaCode":691},
  {"areaName":"Faroe Islands","areaCode":298},
  {"areaName":"France","areaCode":33},
  {"areaName":"Gabon","areaCode":241},
  {"areaName":"United Kingdom","areaCode":44},
  {"areaName":"Grenada","areaCode":1473},
  {"areaName":"Georgia","areaCode":995},
  {"areaName":"French Guiana","areaCode":594},
  {"areaName":"Ghana","areaCode":233},
  {"areaName":"Gibraltar","areaCode":350},
  {"areaName":"Greenland","areaCode":299},
  {"areaName":"Gambia","areaCode":220},
  {"areaName":"Guinea","areaCode":224},
  {"areaName":"Guadeloupe","areaCode":590},
  {"areaName":"Equatorial Guinea","areaCode":240},
  {"areaName":"Greece","areaCode":30},
  {"areaName":"Guatemala","areaCode":502},
  {"areaName":"Guam","areaCode":1671},
  {"areaName":"Guinea-Bissau","areaCode":245},
  {"areaName":"Guyana","areaCode":592},
  {"areaName":"Hong Kong","areaCode":852},
  {"areaName":"Honduras","areaCode":504},
  {"areaName":"Croatia","areaCode":385},
  {"areaName":"Haiti","areaCode":509},
  {"areaName":"Hungary","areaCode":36},
  {"areaName":"Indonesia","areaCode":62},
  {"areaName":"Ireland","areaCode":353},
  {"areaName":"Israel","areaCode":972},
  {"areaName":"India","areaCode":91},
  {"areaName":"Iraq","areaCode":964},
  {"areaName":"Iran","areaCode":98},
  {"areaName":"Iceland","areaCode":354},
  {"areaName":"Italy","areaCode":39},
  {"areaName":"Jamaica","areaCode":1876},
  {"areaName":"Jordan","areaCode":962},
  {"areaName":"Japan","areaCode":81},
  {"areaName":"Kenya","areaCode":254},
  {"areaName":"Kyrgyzstan","areaCode":996},
  {"areaName":"Cambodia","areaCode":855},
  {"areaName":"Kiribati","areaCode":686},
  {"areaName":"Comoros","areaCode":269},
  {"areaName":"Saint Kitts and Nevis","areaCode":1869},
  {"areaName":"North Korea","areaCode":850},
  {"areaName":"South Korea","areaCode":82},
  {"areaName":"Kuwait","areaCode":965},
  {"areaName":"Cayman Islands","areaCode":1345},
  {"areaName":"Kazakhstan","areaCode":7},
  {"areaName":"Laos","areaCode":856},
  {"areaName":"Lebanon","areaCode":961},
  {"areaName":"Saint Lucia","areaCode":1758},
  {"areaName":"Liechtenstein","areaCode":423},
  {"areaName":"Sri Lanka","areaCode":94},
  {"areaName":"Liberia","areaCode":231},
  {"areaName":"Lesotho","areaCode":266},
  {"areaName":"Lithuania","areaCode":370},
  {"areaName":"Luxembourg","areaCode":352},
  {"areaName":"Latvia","areaCode":371},
  {"areaName":"Libya","areaCode":218},
  {"areaName":"Morocco","areaCode":212},
  {"areaName":"Monaco","areaCode":377},
  {"areaName":"Moldova","areaCode":373},
  {"areaName":"Montenegro","areaCode":382},
  {"areaName":"Madagascar","areaCode":261},
  {"areaName":"Marshall Islands","areaCode":692},
  {"areaName":"Macedonia","areaCode":389},
  {"areaName":"Mali","areaCode":223},
  {"areaName":"Myanmar","areaCode":95},
  {"areaName":"Mongolia","areaCode":976},
  {"areaName":"Macau","areaCode":853},
  {"areaName":"Mauritania","areaCode":222},
  {"areaName":"Montserrat","areaCode":1664},
  {"areaName":"Malta","areaCode":356},
  {"areaName":"Mauritius","areaCode":230},
  {"areaName":"Maldives","areaCode":960},
  {"areaName":"Malawi","areaCode":265},
  {"areaName":"Mexico","areaCode":52},
  {"areaName":"Malaysia","areaCode":60},
  {"areaName":"Mozambique","areaCode":258},
  {"areaName":"Namibia","areaCode":264},
  {"areaName":"New Caledonia","areaCode":687},
  {"areaName":"Niger","areaCode":227},
  {"areaName":"Nigeria","areaCode":234},
  {"areaName":"Nicaragua","areaCode":505},
  {"areaName":"Netherlands","areaCode":31},
  {"areaName":"Norway","areaCode":47},
  {"areaName":"Nepal","areaCode":977},
  {"areaName":"Nauru","areaCode":674},
  {"areaName":"New Zealand","areaCode":64},
  {"areaName":"Oman","areaCode":968},
  {"areaName":"Panama","areaCode":507},
  {"areaName":"Peru","areaCode":51},
  {"areaName":"French Polynesia","areaCode":689},
  {"areaName":"Papua New Guinea","areaCode":675},
  {"areaName":"Philippines","areaCode":63},
  {"areaName":"Pakistan","areaCode":92},
  {"areaName":"Poland","areaCode":48},
  {"areaName":"Saint Pierreand Miquelon","areaCode":508},
  {"areaName":"Puerto Rico","areaCode":1787},
  {"areaName":"Portugal","areaCode":351},
  {"areaName":"Palau","areaCode":680},
  {"areaName":"Paraguay","areaCode":595},
  {"areaName":"Qatar","areaCode":974},
  {"areaName":"Réunion Island","areaCode":262},
  {"areaName":"Romania","areaCode":40},
  {"areaName":"Serbia","areaCode":381},
  {"areaName":"Russia","areaCode":7},
  {"areaName":"Rwanda","areaCode":250},
  {"areaName":"Saudi Arabia","areaCode":966},
  {"areaName":"Solomon Islands","areaCode":677},
  {"areaName":"Seychelles","areaCode":248},
  {"areaName":"Sudan","areaCode":249},
  {"areaName":"Sweden","areaCode":46},
  {"areaName":"Singapore","areaCode":65},
  {"areaName":"Slovenia","areaCode":386},
  {"areaName":"Slovakia","areaCode":421},
  {"areaName":"Sierra Leone","areaCode":232},
  {"areaName":"San Marino","areaCode":378},
  {"areaName":"Senegal","areaCode":221},
  {"areaName":"Somalia","areaCode":252},
  {"areaName":"Suriname","areaCode":597},
  {"areaName":"Sao Tome and Principe","areaCode":239},
  {"areaName":"ElSalvador","areaCode":503},
  {"areaName":"Syria","areaCode":963},
  {"areaName":"Swaziland","areaCode":268},
  {"areaName":"Turksand Caicos Islands","areaCode":1649},
  {"areaName":"Chad","areaCode":235},
  {"areaName":"Togo","areaCode":228},
  {"areaName":"Thailand","areaCode":66},
  {"areaName":"Tajikistan","areaCode":992},
  {"areaName":"East Timor","areaCode":670},
  {"areaName":"Turkmenistan","areaCode":993},
  {"areaName":"Tunisia","areaCode":216},
  {"areaName":"Tonga","areaCode":676},
  {"areaName":"Turkey","areaCode":90},
  {"areaName":"Trinidadand Tobago","areaCode":1868},
  {"areaName":"Taiwan","areaCode":886},
  {"areaName":"Tanzania","areaCode":255},
  {"areaName":"Ukraine","areaCode":380},
  {"areaName":"Uganda","areaCode":256},
  {"areaName":"United States","areaCode":1},
  {"areaName":"Uruguay","areaCode":598},
  {"areaName":"Uzbekistan","areaCode":998},
  {"areaName":"Saint Vincent and The Grenadines","areaCode":1784},
  {"areaName":"Venezuela","areaCode":58},
  {"areaName":"VirginIslands,British","areaCode":1284},
  {"areaName":"Vietnam","areaCode":84},
  {"areaName":"Vanuatu","areaCode":678},
  {"areaName":"Samoa","areaCode":685},
  {"areaName":"Yemen","areaCode":967},
  {"areaName":"Mayotte","areaCode":269},
  {"areaName":"South Africa","areaCode":27},
  {"areaName":"Zambia","areaCode":260},
  {"areaName":"Zimbabwe","areaCode":263},
  ]