export function errCodeList(){
    return{
        "Y":'Successful authentication/account verification',
        "A":'Attempted processing was performed',
        "C":'Authentication challenge required',
        "N":'Unverified/unconfirmed account. refusal of transaction',
        "U":'Failed to perform authentication/account verification',
        "R":'Authentication/Account Verification Denied',
        // Credit card payment common error code (E series/M series)
        "E00":'Error code E00',
        "E01":'Error code E01',
        "E11":'Error code E11',
        "E21":'3D Secure Authentication Error (Registration Confirmation)',
        "E31":'Merchant execution error',
        "E41":'Card number check digit error (host detection)',
        "E61":'Merchant setting error',
        "E82":'Execution exclusive error',
        "E90":'Double transmission error (communication)',
        "E91":'System error (communication)',
        "E92":'Flow limit over error',
        "EX1":'Token error',
        "M01":'Input parameter error',
        // CAFIS or card company return error code (C series)
        "C01":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen.',
        "C03":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen.',
        "C12":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen.',
        "C13":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen.',
        "C14":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen.',
        "C15":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen.',
        "C33":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen.',
        "C50":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen.',
        "C51":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen.',
        "C53":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen.',
        "C54":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen.',
        "C55":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen.',
        "C56":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen.',
        "C57":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen.',
        "C58":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen.',
        "C60":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen.',
        "C70":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen.',
        "C71":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen.',
        "C72":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen.',
        "C73":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen.',
        "C74":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen.',
        "C75":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen.',
        "C76":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen.',
        "C77":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen.',
        "C78":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen.',
        // Card company return error code (G series)
        "G02":'The payment could not be completed due to insufficient card balance.',
        "G03":'The payment could not be completed because the card limit was exceeded.',
        "G04":'The payment could not be completed due to insufficient card balance.',
        "G05":'The payment could not be completed because the card limit was exceeded.',
        "G06":'My debit card is out of balance in my account.',
        "G07":'The payment could not be completed because the card limit was exceeded.',
        "G12":'You cannot make transactions with this card.',
        "G22":'You cannot make transactions with this card.',
        "G30":'You cannot make transactions with this card.',
        "G42":'The payment could not be completed because the PIN was incorrect.',
        "G43":'The payment could not be completed because the PIN was incorrect.',
        "G44":'The payment could not be completed because the security code was incorrect.',
        "G45":'The payment could not be completed because the security code was not entered.',
        "G54":'You cannot make transactions with this card.',
        "G55":'The payment could not be completed because the card limit was exceeded.',
        "G56":'You cannot make transactions with this card.',
        "G60":'You cannot make transactions with this card.',
        "G61":'You cannot make transactions with this card.',
        "G65":'The payment could not be completed due to an incorrect card number.',
        "G67":'The payment could not be completed due to an incorrect product code.',
        "G68":'The payment could not be completed due to an incorrect amount.',
        "G69":'The settlement could not be completed due to an error in the tax shipping fee.',
        "G70":'The payment could not be completed due to an incorrect number of bonuses.',
        "G71":'The settlement could not be completed due to an error in the bonus month.',
        "G72":'The payment could not be completed due to an incorrect bonus amount.',
        "G73":'The payment could not be completed due to an error in the payment start month.',
        "G74":'The settlement could not be completed due to an error in the number of Installment.',
        "G75":'The settlement could not be completed due to an error in the Installment amount.',
        "G76":'The settlement could not be completed due to an error in the initial amount.',
        "G77":'The settlement could not be completed due to an error in the business division.',
        "G78":'The payment could not be completed due to an error in the payment category.',
        "G79":'The settlement could not be completed due to an error in the inquiry category.',
        "G80":'The settlement could not be completed due to an error in the Cancel transaction (on the same date) category.',
        "G81":'The settlement could not be completed due to an error in the Cancel transaction (on the same date) handling category.',
        "G83":'The payment could not be completed due to an incorrect expiration date.',
        "G84":'You cannot make transactions with this card.',
        "G85":'The payment could not be completed because the user account could not be used.',
        "G91":'The payment could not be completed due to a system failure.',
        "G92":'You cannot make transactions with this card.',
        "G95":'You cannot make transactions with this card.',
        "G96":'You cannot make transactions with this card.',
        "G97":'You cannot make transactions with this card.',
        "G98":'You cannot make transactions with this card.',
        "G99":'You cannot make transactions with this card.',
    }
}

export function errInfoList(){
    return{
        "01":'Card authentication failed',
        "02":'Unknown Device',
        "03":'Unsupported device',
        "04":'Exceeded the upper limit of authentication frequency',
        "05":'Expired card',
        "06":'invalid card number',
        "07":'Invalid transaction',
        "08":'Card record does not exist',
        "09":'Security failure',
        "10":'Stolen card',
        "11":'Suspected Fraud',
        "12":'Transaction not authorized for cardholder',
        "13":'Cardholder not enrolled in service',
        "14":'Transaction timed out in ACS',
        "15":'Low confidence',
        "16":'moderate confidence',
        "17":'High reliability',
        "18":'Very high confidence',
        "19":'Exceed ACS Max Challenge',
        "20":'Non-settlement transactions are not supported',
        "21":'3RI transactions not supported',
        "22":'ACS Technical Issues',
        "26":'3RI transactions not supported',
        "80-99":'ACS Technical Issues',
         // Credit card payment common error code (E series/M series)
         "E00000001":'Error code E00000001',
         "E00000002":'Error code E00000002',
         "E00000003":'Error code E00000003',
         "E00000010":'Error code E00000010',
         "E01010001":'The shop ID is not specified.',
         "E01010008":'The shop ID contains characters other than single-byte alphanumerical characters or exceeds 13 characters.',
         "E01010010":'The shop IDs do not match.',
         "E01020001":'No shop password has been specified.',
         "E01020008":'The shop password contains characters other than single-byte alphanumerical characters or exceeds 10 characters.',
         "E01030002":'The shop with the specified ID and password does not exist.',
         "E01030061":'Compulsory returns are not available.',
         "E01040001":'The order ID is not specified.',
         "E01040003":'The order ID exceeds the maximum number of characters.',
         "E01040010":'The order ID already exists.',
         "E01040013":'The order ID contains single-byte alphanumerical characters and characters other than "-".',
         "E01050001":'No processing category is specified.',
         "E01050002":'The specified processing category is not defined.',
         "E01050004":'The processing of the specified processing category cannot be executed.',
         "E01060001":'The usage amount is not specified.',
         "E01060005":'The usage amount exceeds the maximum number of digits.',
         "E01060006":'The usage amount contains characters other than numbers.',
         "E01060010":'The transaction usage amount and the specified usage amount do not match.',
         "E01060011":'The usage amount is out of the valid range.',
         "E01060021":'The transaction usage amount and the specified usage amount do not match.',
         "E01070005":'The tax shipping cost exceeds the maximum number of digits.',
         "E01070006":'Tax Shipping includes non-numeric characters.',
         "E01080007":'A value other than 0,1,2 is specified for the 3D Secure use flag.',
         "E01080010":'The 3D Secure use flag (TdFlag) specification does not match your 3D Secure contract.* Use 3DS1.0 without a 3DS1.0 contract',
         "E01080101":'The 3D Secure use flag is OFF even though the 3DS required store or the 3DS required type is specified as 3DS required.',
         "E01090001":'No transaction ID is specified.',
         "E01090008":'The transaction ID format is incorrect.',
         "E01100001":'No transaction password has been specified.',
         "E01100008":'The transaction password format is incorrect.',
         "E01110002":'The specified ID and password transaction does not exist.',
         "E01110010":'The specified transaction has not been settled.',
         "E01130012":'The card company abbreviation exceeds the maximum number of bytes.',
         "E01160001":'The number of bonus splits is not specified.',
         "E01160007":'The number of bonus splits includes non-numeric characters.',
         "E01160010":'A value other than "2" is specified for the number of bonus splits.',
         "E01170001":'The card number is not specified.',
         "E01170003":'The card number exceeds the maximum number of characters.',
         "E01170006":'The card number contains non-numeric characters.',
         "E01170011":'The card number is not in the range of 10 to 16 digits.',
         "E01180001":'No expiration date has been specified.',
         "E01180003":'The expiration date is not 4 digits.',
         "E01180006":'The expiration date contains non-numeric characters.',
         "E01180008":'The expiration date format is incorrect.',
         "E01180011":'The expiration date format is incorrect.',
         "E01190001":'No site ID is specified.',
         "E01190008":'The format of the site ID is incorrect.',
         "E01200001":'No site password has been specified.',
         "E01200007":'The site ID is incorrect.',
         "E01200008":'The site password format is incorrect.',
         "E01210002":'The site with the specified ID and password does not exist.',
         "E01220001":'No member ID has been specified.',
         "E01220005":'The member ID exceeds the maximum number of digits.',
         "E01220008":'The format of the member ID is incorrect.',
         "E01220010":'The member ID and card number match.',
         "E01220012":'The length of the member ID is incorrect.',
         "E01230001":'Card registration serial number is not specified.',
         "E01230006":'The card registration serial number contains characters other than numbers.',
         "E01230009":'The card registration serial number exceeds the maximum number that can be registered.',
         "E01240002":'The specified card does not exist.',
         "E01240012":'The specified member ID is duplicated in the file (* at the time of rewashing)',
         "E01250008":'The format of the card password is incorrect.',
         "E01250010":'The card passwords do not match.',
         "E01260001":'No payment method is specified.',
         "E01260002":'The specified payment method does not exist.',
         "E01260010":'The specified card number or payment method is incorrect.',
         "E01270001":'The number of payments is not specified.',
         "E01270005":'The number of payments exceeds the maximum number of digits.',
         "E01270006":'Contains non-numeric characters for the number of payments.',  
         "E01270010":'The specified number of payments cannot be used.',
         "E01290001":'HTTP_ACCEPT is not specified.',
         "E01300001":'HTTP_USER_AGENT is not specified.',
         "E01310001":'A value other than "0" and "1" is specified for the terminal used.',
         "E01320012":'The value of Merchant Free Item 1 exceeds the maximum number of bytes.',
         "E01320013":'The value of Merchant Free Item 1 contains characters that cannot be used.',
         "M01039013":'Merchant Free Item 1 contains invalid characters.',
         "E01330012":'The value of Merchant Free Item 2 exceeds the maximum number of bytes.',
         "E01330013":'The value of Merchant Free Item 2 contains characters that cannot be used.',
         "M01040013":'Illegal characters are included in Merchant Free Item 2.', 
         "E01340012":'The value of Merchant Free Item 3 exceeds the maximum number of bytes.',
         "E01340013":'The value of Merchant Free Item 3 contains characters that cannot be used.',
         "M01041013":'Illegal characters are included in Merchant Free Item 3.',
         "E01350001":'MD is not specified.',
         "E01350008":'The MD format is incorrect.',
         "E01360001":'PaRes is not specified.',
         "E01370008":'The format of the 3D Secure display store name is incorrect.',
         "E01370012":'The value of the 3D Secure display store name exceeds the maximum number of bytes.',
         "E01390002":'The member with the specified site ID and member ID does not exist.',
         "E01390010":'A member with the specified site ID and member ID already exists.',
         "E01400007":'A value other than "0" and "1" is specified for the Merchant free item return flag.',
         "E01410010":'The corresponding transaction is in an operation prohibited state.',
         "E01420010":'Authorization validity period has been exceeded.',
         "E01430010":'The member name and card number match.',
         "E01430012":'The value of the member name exceeds the maximum number of bytes.',
         "E01440008":'The format of the Traditional Recurring Billing (with Account Updater) flag is incorrect.',
         "E01450008":'The product code format is incorrect.',
         "E01460008":'The security code format is incorrect.',
         "E01470008":'The format of the card registration serial number mode is incorrect.',
         "E01480008":'The format of the holder is incorrect.',
         "E01480011":'The maximum number of characters for the holder has been exceeded.',
         "E01490005":'The total value of usage amount and tax shipping fee exceeds the valid range.',
         "E01500001":'Shop Configuration string is not set.',
         "E01500005":'The number of characters in the Shop Configuration string is incorrect.', 
         "E01500012":'The Shop Configuration string is inconsistent with other items.',
         "E01510001":'The purchase information string has not been set.',
         "E01510005":'The number of characters in the purchase information string is incorrect.',
         "E01510010":'The usage date format is incorrect.',
         "E01510011":'The value of the usage date is out of the specifiable range.',
         "E01510012":'The purchase information string is inconsistent with other items.',
         "E01520002":'An invalid value is set in the End User terminal information.',
         "E01530001":'The payment result return URL has not been set.',
         "E01530005":'The payment result return URL exceeds the maximum number of characters.',
         "E01540005":'The URL when canceling payment exceeds the maximum number of characters.',
         "E01550001":'The date and time information character string is not set.',
         "E01550005":'The number of characters in the date and time information string is incorrect.',
         "E01550006":'The date and time information string contains invalid characters.',
         "E01590005":'The product code exceeds the maximum number of digits.',
         "E01590006":'The product code contains invalid characters.',
         "E01600001":'The member information check character string is not set.',
         "E01600005":'The member information check character string exceeds the maximum number of characters.',
         "E01600012":'The member information check string is inconsistent with other items.',
         "E01610005":'The number of retries is out of the range of 0 to 99.',
         "E01610006":'The number of retries is set to something other than a number.',
         "E01620005":'The session timeout value is outside the range 0-9999.',
         "E01620006":'The session timeout value is set to something other than a number.',
         "E01630010":'When registering a card after transaction, the member ID of the transaction and the member ID of the parameter do not match.',
         "E01640010":'When registering a card after transaction, the transaction site ID and the parameter site ID do not match.', 
         "E01650012":'The specified shop does not belong to the specified site.',
         "E01660013":'The language parameter has an unsupported value set.',
         "E01670013":'An unsupported value is set for the output encoding.',
         "E01700001":'The number of items is incorrect.',
         "E01710001":'The transaction category (Recurring Billing) is not set.',
         "E01710002":'The specified transaction category does not exist.',
         "E01730001":'No bonus amount has been specified.',
         "E01730005":'The bonus amount exceeds the maximum number of digits.',
         "E01730006":'The product code is not "0000990".',
         "E01730007":'The bonus amount contains non-numeric characters.',
         "E01740001":'The terminal processing serial number is not specified.',
         "E01740005":'The terminal processing serial number exceeds the maximum number of digits.',
         "E01740007":'The terminal processing serial number contains characters other than numbers.',
         "E01750001":'The usage date is not specified.',
         "E01750008":'The usage date format is incorrect.',
         "E01770002":'The classification is incorrect.',
         "E01780002":'Whether or not the validity check is invalid.',
         "E01790007":'The check date is invalid.',
         "E01790011":'The check implementation date exceeds the maximum number of digits.',
         "E01800001":'The PIN has not been entered.',
         "E01800008":'The PIN format is incorrect.',
         "E01800010":'PIN cannot be used.',
         "E01800050":'The security code is invalid. (0000 cannot be used)',
         "E01810001":'The magnetic stripe classification is invalid.', 
         "E01810008":'The magnetic stripe classification is invalid.',
         "E01820001":'The magnetic stripe information is invalid.',
         "E01820003":'The magnetic stripe information is invalid.',
         "E01820008":'The magnetic stripe information is invalid.',
         "E01840010":'The required input parameters have not been specified.',
         "E01850008":'The format of the update category is incorrect.',
         "E01860008":'The format of the card number mask flag is incorrect.',
         "E01870008":'The token type format is incorrect.',
         "E01880001":'The registered member ID is not specified.',
         "E01880002":'The member with the specified site ID and registered member ID does not exist.',
         "E01880008":'The format of the registered member ID is incorrect.',
         "E01890001":'Registered card registration serial number is not specified.',
         "E01890002":'The specified registered card does not exist.',
         "E01890006":'The registered card registration serial number contains characters other than numbers.',
         "E01890009":'The card registration serial number exceeds the maximum number that can be registered.',
         "E01910008":'A value other than "0" and "1" is specified for whether to use the mask level in the site settings.',
         "E01920008":'The search type format is incorrect.',
         "E01950008":'When 3DS2.0 is not supported The handling format is incorrect.',
         "E01960008":'The format of the members last update date is incorrect.',
         "E01970008":'Member creation date format is incorrect.',
         "E01980008":'The format of member password change date is incorrect.',
         "E01990005":'The number of purchases in the last 6 months has exceeded the maximum number of digits.',
         "E01990006":'The number of purchases in the last 6 months contains non-numeric characters.',
         "E01999998":'Item 1 "001" is not specified in "Format version".',
         "E01A00008":'The format of the card registration date is incorrect.',
         "E01A10005":'The number of card addition attempts in the last 24 hours has exceeded the maximum number of digits.',
         "E01A10006":'The number of card addition attempts in the last 24 hours contains non-numeric characters.',
         "E01A20008":'The format of the first use date of the shipping address is incorrect.',
         "E01A30008":'The format of the match / mismatch between the cardholder name and the shipping address name is incorrect.',
         "E01A40008":'The format of the cardholders suspicious activity information is incorrect.',
         "E01A50005":'The number of transactions in the last 24 hours has exceeded the maximum number of digits.',
         "E01A50006":'The number of transactions in the last 24 hours contains non-numeric characters.',
         "E01A60005":'The number of transactions in the previous year exceeds the maximum number of digits.',
         "E01A60006":'The number of transactions in the previous year contains non-numeric characters.',
         "E01A70012":'The login trail exceeds the maximum number of bytes.',
         "E01A80008":'The login method format is incorrect.',
         "E01A90008":'The login date and time format is incorrect.',
         "E01B00008":'The format of the match / mismatch between the billing address and the shipping address is incorrect.',
         "E01B10005":'The city of the billing address exceeds the maximum number of digits.',
         "E01B20002":'The country code of the billing address does not exist.',
         "E01B20005":'The country code of the billing address is not 3 digits.',
         "E01B30005":'The first line of the area part of the billing address exceeds the maximum number of digits.', 
         "E01B40005":'The second line of the area part of the billing address exceeds the maximum number of digits.',
         "E01B50005":'The third line of the area part of the billing address exceeds the maximum number of digits.',
         "E01B60005":'The postal code of the billing address exceeds the maximum number of digits.',
         "E01B70005":'The state or state number of your billing address exceeds the maximum number of digits.',
         "E01B70008":'The billing address state or state number is not in the correct format.',
         "E01B70010":'You cannot omit the country code of the billing address when you specify the state or state code of the billing address.',
         "E01B80005":'The cardholder email address exceeds the maximum number of digits.',
         "E01B80008":'The format of the cardholder email address is incorrect.',
         "E01B90005":'The country code of your home phone exceeds the maximum number of digits.',
         "E01C00005":'Your home phone number exceeds the maximum number of digits.',
         "E01C00006":'Your home phone number contains non-numeric characters.',
         "E01C10005":'The country code of the mobile phone exceeds the maximum number of digits.',
         "E01C20005":'The mobile phone number exceeds the maximum number of digits.',
         "E01C20006":'The mobile phone number contains non-numeric characters.',
         "E01C30005":'The country code of your work phone exceeds the maximum number of digits.',
         "E01C40005":'The work phone number exceeds the maximum number of digits.',
         "E01C40006":'Your work phone number contains non-numeric characters.',
         "E01C50005":'The city of the shipping address exceeds the maximum number of digits.',
         "E01C60002":'The country code of the shipping address does not exist.',
         "E01C60005":'The country code of the shipping address is not 3 digits.',
         "E01C70005":'The first line of the area part of the shipping address exceeds the maximum number of digits.',
         "E01C80005":'The second line of the area part of the shipping address exceeds the maximum number of digits.',
         "E01C90005":'The third line of the area part of the shipping address exceeds the maximum number of digits.',
         "E01D00005":'The postal code of the shipping address exceeds the maximum number of digits.', 
         "E01D10005":'The state or state number format of the shipping address is incorrect.',
         "E01D10008":'The state or state number of the shipping address exceeds the maximum number of digits.',
         "E01D10010":'You cannot omit the country code of the shipping address when you specify the state or state code of the shipping address.',
         "E01D20005":'The delivery email address exceeds the maximum number of digits.',
         "E01D20008":'The delivery email address is not in the correct format.',
         "E01D30008":'The format of the product delivery time frame is incorrect.',
         "E01D40005":'The total purchase price of a prepaid card or gift card exceeds the maximum number of digits.',
         "E01D40006":'The total purchase price of your prepaid or gift card contains non-numeric characters.',
         "E01D50005":'The total number of prepaid or gift cards purchased has exceeded the maximum number of digits.',
         "E01D50006":'The total number of prepaid or gift cards purchased contains non-numeric characters.',
         "E01D60005":'The currency code of the prepaid card or gift card purchased is not 3 digits.',
         "E01D70008":'The format of the planned product release date is incorrect.',
         "E01D80008":'The format of the product sales time information is incorrect.',
         "E01D90008":'The format of the order information for the item is incorrect.',
         "E01E00008":'The format of the shipping method for the transaction is incorrect.',
         "E01E10008":'The format of the renewal Recurring Billing deadline is incorrect.',
         "E01E20005":'The minimum billing interval days for Recurring Billing exceeds the maximum number of digits.',
         "E01E20006":'The minimum billing interval days for recurring Recurring Billing contains non-numeric characters.',
         "E01E30001":'Merchant return URL is not specified.',
         "E01E30005":'Merchant return URL exceeds the maximum number of digits.',
         "E01E30008":'The format of the Merchant return URL is incorrect.',
         "E01E50001":'No authentication status is specified.',
         "E01E50004":'Cannot execute because the authentication status is invalid',
         "E01E50008":'The authentication status format is incorrect.', 
         "E01E60001":'The cardholder login information specified is incorrect. If any of login method/login trail data/login date/time is specified, the other two cannot be omitted.',
         "E01E70001":'The home phone number is specified incorrectly. You cannot omit either the country code of your home phone or your home phone number.',
         "E01E80001":'The mobile phone number is specified incorrectly. Either the country code / mobile phone number of the mobile phone cannot be omitted.',
         "E01E90001":'The work phone number is specified incorrectly. You cannot omit either the country code / work phone number of your work phone.',
         "E01EA0007":'A value other than 1 or 2 is specified for the callback method.',
         "E01EA0010":'A value other than 1 or 2 is specified for the callback method.',
         "E01EB0001":'No 3DS2.0 authentication parameters are specified.',
         "E01EB0005":'The 3DS2.0 authentication parameter exceeds the maximum number of digits.',
         "E01EC0002":'The specified 3DS SDK interface is undefined.',
         "E01EE0001":'The app ID is not specified.',
         "E01EE0008":'The format of the app ID is incorrect.',
         "E01EF0001":'3DS2.0 encrypted data is not specified.',
         "E01EF0005":'The 3DS 2.0 encrypted data exceeds the maximum number of digits.',
         "E01EG0001":'3DS2.0 JWS is not specified.',
         "E01EG0008":'The format of 3DS2.0 JWS is incorrect.',
         "E01EV0001":'No maximum timeout has been specified.',
         "E01EV0008":'The maximum timeout format is incorrect.',
         "E01EV0006":'The maximum timeout contains non-numeric characters.',
         "E01EW0001":'No reference number is specified.',
         "E01EW0005":'The reference number exceeds the maximum number of digits.',
         "E01EX0001":'SDK transaction ID is not specified.',
         "E01EX0008":'The SDK transaction ID format is incorrect.',
         "E01EY0007":'The mobile app mode is specified incorrectly.',
         "E01EZ0008":'The format of the 3DS required type is incorrect.',
         "E11010001":'This transaction has already been settled.',
         "E11010002":'This transaction has not been settled and cannot be changed.',
         "E11010003":'This transaction cannot be processed by designated processing.',
         "E11010004":'The specified transaction callback method or mobile app mode is incorrect.',
         "E11010010":'The transaction cannot be processed for more than 180 days.',
         "E11010099":'This card cannot be used.',
         "E11010100":'This card cannot be used.',
         "E11010999":'nothing special',
         "E11310001":'This transaction cannot perform linked payments.',
         "E11310002":'This transaction cannot perform linked payments.',
         "E11310003":'This transaction cannot perform linked payments.',
         "E11310004":'This transaction cannot perform linked payments.',
         "E11310005":'Members who have already registered their cards cannot perform post-transaction card registration.',
         "E21010001":'3D Secure authentication failed. Please try again from the purchase screen.',
         "E21010007":'3D Secure authentication failed. Please try again from the purchase screen.',
         "E21010999":'3D Secure authentication failed. Please try again from the purchase screen.',
         "E21020001":'3D Secure authentication failed. Please try again from the purchase screen.',
         "E21020002":'3D Secure Authentication has been cancelled. Please try again from the purchase screen.', 
         "E21020007":'3D Secure authentication failed. Please try again from the purchase screen.',
         "E21020999":'3D Secure authentication failed. Please try again from the purchase screen.',
         "E21010201":'You cannot make transactions with this card. Please use a card that supports 3D Secure authentication.',
         "E21010202":'You cannot make transactions with this card. Please use a card that supports 3D Secure authentication.',
         "E21030001":'3D Secure authentication failed. Please try again from the purchase screen.',
         "E21030007":'3D Secure authentication failed. Please try again from the purchase screen.',
         "E21030201":'You cannot make transactions with this card. Please use a card that supports 3D Secure authentication.',
         "E21030202":'You cannot make transactions with this card. Please use a card that supports 3D Secure authentication.',
         "E21040001":'Support for 3DS 1.0 has ended, but we have stopped trading because there is no set handling after the end of support.',
         "E21040002":'3DS 1.0 is no longer supported and cannot be used.',
         "E31500014":'Error code E31500014',
         "E41170099":'The card number is incorrect. Please check again and enter.',
         "E61010001":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen.',
         "E61010002":'You have used an unusable card or the card number is incorrect.',
         "E61010003":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen.',
         "E61020001":'The specified payment method has been suspended.',
         "E61030001":'Contract details error / Cannot be used with the current contract.',
         "E61040001":'The current contract does not allow payment processing with a card number.',
         "E82010001":'An error occurred during execution. The process did not start.',
         "E90010001":'We are currently processing it, so please wait for a while.',
         "E91099996":'Internal system error. Please contact us after confirming the occurrence time and calling parameters.',
         "E91099997":'The requested API does not exist. Please check the URL.',
         "E91019999":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen.',
         "E91020001":'A communication timeout has occurred. We apologize for the inconvenience, but please wait a while and try again from the purchase screen.', 
         "E91029998":'Payment processing failed. Please contact the store for the relevant transaction.',
         "E91029999":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen.',
         "E91060001":'Internal system error. Please contact us after confirming the occurrence time and calling parameters.',
         "E91099999":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen.',
         "E92000001":"It's very crowded right now, so please wait a while and try again.",
         "E92000002":"It's very crowded right now, so please wait a while and try again.",
         "EX1000301":'Payment processing failed. Please enter your card number again.',
         "EX1000302":'Payment processing failed. Please enter your card number again.',
         "EX1000303":'Payment processing failed. Please enter your card number again.',
         "EX1000304":'Payment processing failed. Please enter your card number again.',
        //  CAFIS or card company return error code (C series)
         "42C010000":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen.',
         "42C030000":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen..',
         "42C120000":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen..',
         "42C130000":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen..',
         "42C140000":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen..',
         "42C150000":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen..',
         "42C330000":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen..',
         "42C500000":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen..',
         "42C510000":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen..',
         "42C530000":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen..',
         "42C540000":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen..',
         "42C550000":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen..',
         "42C560000":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen..',
         "42C570000":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen..', 
         "42C580000":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen..',
         "42C600000":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen..',
         "42C700000":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen..',
         "42C710000":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen..',
         "42C720000":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen..',
         "42C730000":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen..',
         "42C740000":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen..',
         "42C750000":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen..',
         "42C760000":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen..',
         "42C770000":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen..',
         "42C780000":'Payment processing failed. We apologize for the inconvenience, but please wait a while and try again from the purchase screen..',
        //  Card company return error code (G series)
         "42G020000":'The payment could not be completed due to insufficient card balance.',
         "42G030000":'The payment could not be completed because the card limit was exceeded.',
         "42G040000":'The payment could not be completed due to insufficient card balance.',
         "42G050000":'The payment could not be completed because the card limit was exceeded.',
         "42G060000":'My debit card is out of balance in my account.',
         "42G070000":'The payment could not be completed because the card limit was exceeded.',
         "42G120000":'You cannot make transactions with this card.',
         "42G220000":'You cannot make transactions with this card.',
         "42G300000":'You cannot make transactions with this card.',
         "42G420000":'The payment could not be completed because the PIN was incorrect.',
         "42G430000":'The payment could not be completed because the PIN was incorrect.',
         "42G440000":'The payment could not be completed because the security code was incorrect.',
         "42G450000":'The payment could not be completed because the security code was not entered.',
         "42G540000":'You cannot make transactions with this card.',
         "42G550000":'The payment could not be completed because the card limit was exceeded.',
         "42G560000":'You cannot make transactions with this card.',
         "42G600000":'You cannot make transactions with this card.',
         "42G610000":'You cannot make transactions with this card.',
         "42G650000":'The payment could not be completed due to an incorrect card number.',
         "42G670000":'The payment could not be completed due to an incorrect product code.',
         "42G680000":'The payment could not be completed due to an incorrect amount.',
         "42G690000":'The settlement could not be completed due to an error in the tax shipping fee.',
         "42G700000":'The payment could not be completed due to an incorrect number of bonuses.',
         "42G710000":'The settlement could not be completed due to an error in the bonus month.',
         "42G720000":'The payment could not be completed due to an incorrect bonus amount.',
         "42G730000":'The payment could not be completed due to an error in the payment start month.',
         "42G740000":'The settlement could not be completed due to an error in the number of Installment.',
         "42G750000":'The settlement could not be completed due to an error in the Installment amount.',
         "42G760000":'The settlement could not be completed due to an error in the initial amount.',
         "42G770000":'The settlement could not be completed due to an error in the business division.',
         "42G780000":'The payment could not be completed due to an error in the payment category.',
         "42G790000":'The settlement could not be completed due to an error in the inquiry category.',
         "42G800000":'The settlement could not be completed due to an error in the Cancel transaction (on the same date) category.',
         "42G810000":'The settlement could not be completed due to an error in the Cancel transaction (on the same date) handling category.',
         "42G830000":'The payment could not be completed due to an incorrect expiration date.',
         "42G840000":'You cannot make transactions with this card.',
         "42G850000":'The payment could not be completed because the user account could not be used.',
         "42G910000":'The payment could not be completed due to a system failure.',
         "42G920000":'You cannot make transactions with this card.',
         "42G950000":'You cannot make transactions with this card.',
         "42G960000":'You cannot make transactions with this card.',
         "42G970000":'You cannot make transactions with this card.',
         "42G980000":'You cannot make transactions with this card.',
         "42G990000":'You cannot make transactions with this card.',
    }
}
