<template>
  <el-dialog title="NOTICE" :visible.sync="confirmDialog"  center :show-close="closeFlag" :close-on-click-modal="closeFlag" :close-on-press-escape="closeFlag"	class="confirmDialog" :fullscreen="screenWidth < 883">
    <checkoutNotice :type="10" />
    <!-- <div v-show="screenWidth > 883"  style="text-align: center;"><el-button type="primary" class="button"  @click="clsoeDialog">I Agree</el-button></div> -->
    <div class="btn_box">
      <el-button type="primary" class="button"  @click="clsoeDialog">I Agree</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { mixin2 } from "@/mixin/index";
import { judgeIphone } from '../../utils/util'
import checkoutNotice from "@/components/checkoutNotice";
export default {
  mixins: [mixin2],
  components: { checkoutNotice },
  data() {
    return {
      confirmDialog: false,
      closeFlag:false,
      ticketItem: {},
      screenWidth: document.body.clientWidth,
      extrasName: ""
    };
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        that.screenWidth = document.body.clientWidth;
      })();
    };
  },
  methods: {
    init() {
      setTimeout(() => {
        this.confirmDialog = true;
      }, 200);
      setTimeout(() => {
      }, 20);
    },
    clsoeDialog(){
      this.closeFlag=true
      this.confirmDialog=false
    },
    confirm() {
      this.confirmDialog = false;
      this.$emit("confirm");
    },
   
  }
};
</script>
<style lang="scss" scoped>
// 删除票时的确认弹框
::v-deep.confirmDialog {
  .el-dialog {
    position: relative;
    border-radius: 16px 16px 12px 12px;
    padding: 0 !important;
    width: 882px;
    @media (max-width: 883px) {
      border-radius: 0;
      width: 100%;
      height: 100vh;
      // height: calc(100vh - var(--vh-offset, 0px));
      margin: 0 !important;
    }
  }
  .el-dialog__body{
    padding: 0;
  }
  .el-dialog__header {
    padding: 20px 20px;
    background-color: #162b75;
    font-weight: 700;
    border-radius: 12px 12px 0 0;
    @media (max-width: 883px) {
      border-radius: 0;
    }
    span {
      color: white;
    }
    .el-dialog__headerbtn .el-dialog__close {
      color: white;
      font-weight: 600;
      font-size: 20px;
    }
  }
  .el-dialog__body {
    @media (max-width: 883px) {
      height: calc(100vh - 157px);
      border-bottom: none;
    }
    font-weight: 500;
    color: #333;
  //  border-bottom: 1px solid #f2f2f2;
  }

}
::v-deep .confirmDialog {
  @media (max-width: 768px) {
    .el-dialog {
      border-radius: 0;
    }
    .el-dialog__header {
      border-radius: 0;
    }
  }
}
    .btn_box{
      position: relative;
      text-align: center;
      padding-top: 20px;
      border-top: 1px solid #f2f2f2;
      button {
      width:95%;
   
      margin-bottom: 30px;
      line-height: 25px;
      border-radius: 10px;
      font-weight: 600;

    
    }
    }
</style>
